export const myProjects = [
  {
    projectName: "E-Shop App",
    projectImg: "eshopapp.webp",
    techUsed: "React JS, Node JS, Express JS, MongoDB, Redux, JWT",
    appUrl: "https://eshop.zarrarpalekar.com/",
    gitUrl: "https://github.com/ZarrarPalekar/ProShop",
    date: "Mar-2021",
    desc: "This is a MERN Full Stack E-Commerce App.",
    features: {
      feat1: "Lets you buy products with sandbox paypal",
      feat2: "Powered by React and Redux",
      feat3: "Responsive Design",
    },
    techImages: {
      img0: "react.svg",
      img1: "nodejs.svg",
      img2: "express.svg",
      img3: "mongodb.svg",
      img4: "redux.svg",
      img5: "JWT.svg",
    },
  },
  {
    projectName: "Dev Social App",
    projectImg: "1DevConnApp.webp",
    techUsed: "React JS, Node JS, Express JS, MongoDB, Redux, JWT",
    appUrl: "https://devsocial.zarrarpalekar.com/",
    gitUrl: "https://github.com/ZarrarPalekar/SocialDevApp",
    date: "Nov-2020",
    desc: "This is a Social Media App created with React and Redux that is basically a social media app for developers with almost all social media functionalities.",
    features: {
      feat1: "Lets you connect with other people",
      feat2: "Powered by React and Redux",
      feat3: "Responsive Design",
    },
    techImages: {
      img0: "react.svg",
      img1: "nodejs.svg",
      img2: "express.svg",
      img3: "mongodb.svg",
      img4: "redux.svg",
      img5: "JWT.svg",
    },
  },
  {
    projectName: "Shopping List App",
    projectImg: "7MernShopList.webp",
    techUsed: "React JS, Node JS, Express JS, MongoDB, Redux, JWT",
    date: "Oct-2020",
    desc: "This is a Shopping List App.",
    features: {
      feat1: "Lets you maintain your shopping list",
      feat2: "Powered by React and Redux",
      feat3: "Responsive Design",
    },
    techImages: {
      img0: "react.svg",
      img1: "nodejs.svg",
      img2: "express.svg",
      img3: "mongodb.svg",
      img4: "redux.svg",
      img5: "JWT.svg",
    },
  },
  {
    projectName: "EJS Todo App",
    projectImg: "2ToDoApp.webp",
    techUsed: "React JS, Node JS, Express JS, MongoDB, EJS",
    date: "Sep-2020",
    desc: "This app is based on EJS and is used to maintain a todo list.",
    features: {
      feat1: "Add To List",
      feat2: "Delete From List",
      feat3:
        "Make Your Own Custom List just by typing in the name you want in the URL after the app url as a querystring",
    },
    techImages: {
      img0: "react.svg",
      img1: "nodejs.svg",
      img2: "express.svg",
      img3: "mongodb.svg",
      img4: "ejs.svg",
    },
  },
  {
    projectName: "My Blogs",
    projectImg: "5Blogs.webp",
    techUsed: "HTML, CSS, Bootstrap, EJS, MongoDB",
    date: "Sep-2020",
    desc: "An app that allows users to see my blog posts.",
    features: {
      feat1: "Users can see my blog list and read more",
      feat2: "I have a admin panel to write my blogs",
    },
    techImages: {
      img0: "html-5.svg",
      img1: "css3.svg",
      img2: "bootstrap-4.svg",
      img3: "ejs.svg",
      img4: "mongodb.svg",
    },
  },
  {
    projectName: "My Newsletter",
    projectImg: "4SignUpNewsLetter.webp",
    techUsed: "EJS, Node JS, Express JS, Mailchimp API",
    date: "Sep-2020",
    desc: "An app that signs you up for my newsletter.",
    features: {
      feat1: "Register new user for newsletter",
      feat2: "Welcome mail to user",
      feat3: "Awesome monthly content to user",
    },
    techImages: {
      img0: "ejs.svg",
      img1: "nodejs.svg",
      img2: "express.svg",
      img3: "mailchimp.svg",
    },
  },
  {
    projectName: "Secrets App",
    projectImg: "3Secrets.webp",
    techUsed: "HTML, CSS, React JS, MongoDB, Google OAuth",
    date: "Aug-2020",
    desc: "An app that posts secrets anonymously.",
    features: {
      feat1: "Post anonymous secrets",
      feat2: "See all anonymous secrets",
      feat3: "Google OAuth",
    },
    techImages: {
      img0: "html-5.svg",
      img1: "css3.svg",
      img2: "react.svg",
      img3: "mongodb.svg",
      img4: "OAuth.svg",
    },
  },
  {
    projectName: "Portfolio Website",
    projectImg: "pzjportfolio.webp",
    techUsed: "React JS, Styled Components, Github Pages, WhatsApp",
    appUrl: "https://www.zarrarpalekar.com/",
    date: "Jul-2020",
    desc: "This portfolio website.",
    features: {
      feat1: "Powered by React",
      feat2: "Used WhatsApp API",
    },
    techImages: {
      img0: "react.svg",
      img1: "styled-components.svg",
      img2: "githubpages.svg",
      img3: "whatsapp.svg",
    },
  },
  {
    projectName: "Tindog - Tinder for dogs",
    projectImg: "6TinDog.webp",
    techUsed: "HTML, CSS, Bootstrap",
    appUrl: "https://tindog.zarrarpalekar.com/",
    date: "Jun-2020",
    desc: "This website is a clone of Tinder and its for your dogs :D.",
    features: {
      feat1: "Tinder Clone for Dogs",
    },
    techImages: {
      img0: "html-5.svg",
      img1: "css3.svg",
      img2: "bootstrap-4.svg",
    },
  },
  {
    projectName: "My First Website",
    projectImg: "8OldCV.webp",
    techUsed: "HTML, CSS, Bootstrap",
    appUrl: "https://oldcv.zarrarpalekar.com/",
    date: "Jun-2020",
    desc: "This website is my earlier portfolio.",
    features: {
      feat1: "My Earlier CV",
    },
    techImages: {
      img0: "html-5.svg",
      img1: "css3.svg",
      img2: "bootstrap-4.svg",
    },
  },
];
